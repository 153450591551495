import { Box, visuallyHidden } from "@outschool/backpack";
import { CurrencyCode } from "@outschool/gql-backend-generated";
import { faGlobeRegular } from "@outschool/icons";
import { useLocalStorageState } from "@outschool/local-storage";
import {
  I18nLocale,
  useCurrencyLocalization,
  useLocale,
  useSetCurrencyLocalization,
  useTranslation,
} from "@outschool/localization";
import {
  OUTSCHOOL_TIMEZONE,
  dayjs,
  formatIANATimeZoneParts,
  formatUTCOffset,
  getAllTimeZoneNames,
  guessBrowserTimeZone,
} from "@outschool/time";
import { SelectOption } from "@outschool/ui-legacy-component-library";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import { LocalizationPickerModal } from "./LocalizationPickerModal";
import { HeaderNavLabel } from "./nav/DesktopHeaderNav";
import HeaderNavItem from "./nav/HeaderNavItem";
import { HeaderMenuItem } from "./nav/MobileHeaderMenuComponent";

const SELECTED_USER_LOCALE_LOCAL_STORAGE_KEY = "selectedUserLocale";
const TIME_ZONE_LOCAL_STORAGE_KEY = "timeZone";

const timeZoneOptionGroups: Record<string, SelectOption<string>[]> = {};
for (let timeZoneName of getAllTimeZoneNames()) {
  const [continent] = formatIANATimeZoneParts(timeZoneName);
  timeZoneOptionGroups[continent] ??= [];
  const timeZoneOption = {
    value: timeZoneName,
    label: `(${formatUTCOffset(timeZoneName)}) ${
      formatIANATimeZoneParts(timeZoneName)[1]
    }`,
  };
  timeZoneOptionGroups[continent].push(timeZoneOption);
}

// Sort timezones within optgroups and remove any duplicates
for (let continent in timeZoneOptionGroups) {
  const thisGroup = timeZoneOptionGroups[continent];
  const sortedGroup = sortBy(
    thisGroup,
    ({ value }) => -dayjs.tz(value).utcOffset()
  );
  const dedupedGroup = uniqBy(sortedGroup, "label");
  timeZoneOptionGroups[continent] = dedupedGroup;
}

export default function HeaderLocalizationButton({
  onClick = () => {},
  isMobile = false,
}: {
  onClick?: () => void;
  isMobile?: Boolean;
}) {
  const { t } = useTranslation(
    "ssr-client\\components\\HeaderLocalizationButton"
  );

  const a11yLabel = t`Open currency, time zone, and language settings`;

  const [currencyCode, setCurrencyCode] = useState<CurrencyCode>(
    CurrencyCode.Usd
  );

  const { currencyCode: localStorageCurrencyCode } = useCurrencyLocalization();
  const { setCurrency: setLocalStorageCurrencyCode } =
    useSetCurrencyLocalization();
  useEffect(() => {
    setCurrencyCode(localStorageCurrencyCode);
  }, [localStorageCurrencyCode]);

  const [timeZone, setTimeZone] = useState<string>(OUTSCHOOL_TIMEZONE);
  const [localStorageTimeZone, setLocalStorageTimeZone] =
    useLocalStorageState<string>(
      TIME_ZONE_LOCAL_STORAGE_KEY,
      guessBrowserTimeZone()
    );
  useEffect(() => {
    setTimeZone(localStorageTimeZone);
  }, [localStorageTimeZone]);

  const activeLocale = useLocale();
  const [_localStorageLocale, setLocalStorageLocale] = useLocalStorageState(
    SELECTED_USER_LOCALE_LOCAL_STORAGE_KEY,
    null
  );

  const [localizationMenuOpen, setLocalizationMenuOpen] =
    useState<boolean>(false);

  const router = useRouter();
  const applyModalChanges = async (
    newLocale: I18nLocale,
    newCurrencyCode: CurrencyCode,
    newTimeZome: string
  ) => {
    await setLocalStorageCurrencyCode(newCurrencyCode);
    await setLocalStorageTimeZone(newTimeZome);
    await setLocalStorageLocale(newLocale);

    setCurrencyCode(newCurrencyCode);
    setTimeZone(newTimeZome);
    if (newLocale !== activeLocale) {
      router.push(router.asPath, router.asPath, { locale: newLocale });
      setLocalizationMenuOpen(false);
    } else {
      setLocalizationMenuOpen(false);
    }
  };

  return (
    <>
      <Box
        onClick={() => {
          setLocalizationMenuOpen(true);
          onClick();
        }}
        data-test-id="header-localization-picker"
      >
        <span style={visuallyHidden}>{a11yLabel}</span>
        {isMobile ? (
          <HeaderMenuItem
            label={t("Language & Region")}
            icon={faGlobeRegular}
          />
        ) : (
          <HeaderNavItem>
            <HeaderNavLabel
              icon={faGlobeRegular}
              label={t("Language & Region")}
              hideLabel={true}
            />
          </HeaderNavItem>
        )}
      </Box>
      <LocalizationPickerModal
        isOpen={localizationMenuOpen}
        setIsOpen={setLocalizationMenuOpen}
        storedCurrencyCode={currencyCode}
        storedTimeZone={timeZone}
        applyChanges={applyModalChanges}
      />
    </>
  );
}
