import {
  Box,
  Icon,
  Theme,
  Typography,
  visuallyHidden,
} from "@outschool/backpack";
import { CurrencyCode } from "@outschool/gql-backend-generated";
import { faGlobe, fasClock } from "@outschool/icons";
import { useLocalStorageState } from "@outschool/local-storage";
import {
  CURRENCIES_INFO,
  I18nLocale,
  getLanguageFromLocale,
  useCurrencyLocalization,
  useLocale,
  useSetCurrencyLocalization,
  useTranslation,
} from "@outschool/localization";
import {
  OUTSCHOOL_TIMEZONE,
  dayjs,
  formatIANATimeZoneName,
  formatIANATimeZoneParts,
  formatUTCOffset,
  getAllTimeZoneNames,
  guessBrowserTimeZone,
} from "@outschool/time";
import { SelectOption } from "@outschool/ui-legacy-component-library";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import { useGetInferredLocale } from "../hooks/useGetInferredLocale";
import { LocalizationPickerModal } from "./LocalizationPickerModal";

const SELECTED_USER_LOCALE_LOCAL_STORAGE_KEY = "selectedUserLocale";
const TIME_ZONE_LOCAL_STORAGE_KEY = "timeZone";

const timeZoneOptionGroups: Record<string, SelectOption<string>[]> = {};
for (let timeZoneName of getAllTimeZoneNames()) {
  const [continent] = formatIANATimeZoneParts(timeZoneName);
  timeZoneOptionGroups[continent] ??= [];
  const timeZoneOption = {
    value: timeZoneName,
    label: `(${formatUTCOffset(timeZoneName)}) ${
      formatIANATimeZoneParts(timeZoneName)[1]
    }`,
  };
  timeZoneOptionGroups[continent].push(timeZoneOption);
}

// Sort timezones within optgroups and remove any duplicates
for (let continent in timeZoneOptionGroups) {
  const thisGroup = timeZoneOptionGroups[continent];
  const sortedGroup = sortBy(
    thisGroup,
    ({ value }) => -dayjs.tz(value).utcOffset()
  );
  const dedupedGroup = uniqBy(sortedGroup, "label");
  timeZoneOptionGroups[continent] = dedupedGroup;
}

export default function FooterLocalizationButton() {
  const { t } = useTranslation(
    "ssr-client\\components\\FooterLocalizationButton"
  );

  const a11yLabel = t`Open currency, time zone, and language settings`;
  const divider = (
    <Typography
      variant="inherit"
      sx={{
        margin: "0 7px",
      }}
    >
      {"|"}
    </Typography>
  );

  const [currencyCode, setCurrencyCode] = useState<CurrencyCode>(
    CurrencyCode.Usd
  );

  const { currencyCode: localStorageCurrencyCode } = useCurrencyLocalization();
  const { setCurrency: setLocalStorageCurrencyCode } =
    useSetCurrencyLocalization();
  useEffect(() => {
    setCurrencyCode(localStorageCurrencyCode);
  }, [localStorageCurrencyCode]);
  const currencySymbol = CURRENCIES_INFO[currencyCode]?.symbol;

  const [timeZone, setTimeZone] = useState<string>(OUTSCHOOL_TIMEZONE);
  const [localStorageTimeZone, setLocalStorageTimeZone] =
    useLocalStorageState<string>(
      TIME_ZONE_LOCAL_STORAGE_KEY,
      guessBrowserTimeZone()
    );
  useEffect(() => {
    setTimeZone(localStorageTimeZone);
  }, [localStorageTimeZone]);
  const timeZoneName = formatIANATimeZoneName(timeZone);

  const activeLocale = useLocale();
  const [locale, setLocale] = useState(activeLocale);
  const languageName = getLanguageFromLocale(locale).localLanguageName;
  const [_localStorageLocale, setLocalStorageLocale] = useLocalStorageState(
    SELECTED_USER_LOCALE_LOCAL_STORAGE_KEY,
    null
  );

  const [localizationMenuOpen, setLocalizationMenuOpen] =
    useState<boolean>(false);

  const inferredLocale = useGetInferredLocale();
  useEffect(() => {
    if (inferredLocale && inferredLocale !== activeLocale) {
      setLocalizationMenuOpen(true);
    }
  }, [inferredLocale, activeLocale]);

  const router = useRouter();
  const applyModalChanges = async (
    newLocale: I18nLocale,
    newCurrencyCode: CurrencyCode,
    newTimeZome: string
  ) => {
    await setLocalStorageCurrencyCode(newCurrencyCode);
    await setLocalStorageTimeZone(newTimeZome);
    await setLocalStorageLocale(newLocale);

    setCurrencyCode(newCurrencyCode);
    setTimeZone(newTimeZome);
    setLocale(newLocale);
    if (newLocale !== activeLocale) {
      router.push(router.asPath, router.asPath, { locale: newLocale });
      setLocalizationMenuOpen(false);
    } else {
      setLocalizationMenuOpen(false);
    }
  };

  return (
    <>
      <Box
        onClick={() => setLocalizationMenuOpen(true)}
        data-test-id="footer-localization-picker"
        sx={(theme: Theme) => ({
          fontSize: theme.typography.pxToRem(14),
          color: "common.white",
        })}
      >
        <button
          style={{
            background: "none",
            color: "inherit",
            border: "none",
            cursor: "pointer",
            padding: 0,
          }}
        >
          <span style={visuallyHidden}>{a11yLabel}</span>
          <Box
            sx={{
              display: "inline-block",
              verticalAlign: "middle",
              paddingRight: "0.5em",
            }}
          >
            <Icon
              aria-hidden
              icon={faGlobe}
              sx={{
                color: "common.white",
                backgroundColor: "primary.700",
                marginRight: "5px",
              }}
            />
            {languageName}
            {divider}
            <Typography
              variant="inherit"
              aria-hidden
              gutterBottom
              sx={{
                fontWeight: "fontWeightBold",
              }}
            >
              {currencySymbol}
            </Typography>
            {currencyCode}
            {divider}
            <Icon
              aria-hidden
              icon={fasClock}
              sx={{
                color: "common.white",
                backgroundColor: "primary.700",
                marginRight: "5px",
              }}
            />
            {timeZoneName}
          </Box>
        </button>
      </Box>
      <LocalizationPickerModal
        isOpen={localizationMenuOpen}
        setIsOpen={setLocalizationMenuOpen}
        storedCurrencyCode={currencyCode}
        storedTimeZone={timeZone}
        applyChanges={applyModalChanges}
      />
    </>
  );
}
