import { Box, Button, Icon, Typography } from "@outschool/backpack";
import { CurrencyCode } from "@outschool/gql-backend-generated";
import { faClock, faCoins, faGlobeRegular } from "@outschool/icons";
import {
  CURRENCIES_INFO,
  I18nLocale,
  SUPPORTED_LANGUAGES,
  SupportedLanguage,
  getSuggestedCurrencyCode,
  useLocale,
  useTranslation,
} from "@outschool/localization";
import {
  OUTSCHOOL_TIMEZONE,
  dayjs,
  formatIANATimeZoneParts,
  formatUTCOffset,
  getAllTimeZoneNames,
} from "@outschool/time";
import { TrackedButton } from "@outschool/ui-components-shared";
import {
  Select,
  SelectOption,
  SelectWithOptGroups,
} from "@outschool/ui-legacy-component-library";
import fromPairs from "lodash/fromPairs";
import sortBy from "lodash/sortBy";
import toPairs from "lodash/toPairs";
import uniqBy from "lodash/uniqBy";
import { useEffect, useState } from "react";

import { useGetInferredLocale } from "../hooks/useGetInferredLocale";
import PageModal from "./PageModal";

interface LocalizationPickerModalProps {
  isOpen: boolean;
  setIsOpen: (a: boolean) => void;
  storedCurrencyCode: CurrencyCode;
  storedTimeZone: string;
  applyChanges: (
    locale: I18nLocale,
    currencyCode: CurrencyCode,
    timeZone: string
  ) => Promise<void>;
}

const timeZoneOptionGroups: Record<string, SelectOption<string>[]> = {};
for (let timeZoneName of getAllTimeZoneNames()) {
  const [continent] = formatIANATimeZoneParts(timeZoneName);
  timeZoneOptionGroups[continent] ??= [];
  const timeZoneOption = {
    value: timeZoneName,
    label: `(${formatUTCOffset(timeZoneName)}) ${
      formatIANATimeZoneParts(timeZoneName)[1]
    }`,
  };
  timeZoneOptionGroups[continent].push(timeZoneOption);
}

// Sort timezones within optgroups and remove any duplicates
for (let continent in timeZoneOptionGroups) {
  const thisGroup = timeZoneOptionGroups[continent];
  const sortedGroup = sortBy(
    thisGroup,
    ({ value }) => -dayjs.tz(value).utcOffset()
  );
  const dedupedGroup = uniqBy(sortedGroup, "label");
  timeZoneOptionGroups[continent] = dedupedGroup;
}

const languageOptions = SUPPORTED_LANGUAGES.map(
  (language: SupportedLanguage) => ({
    value: language.i18nLocale,
    label: language.localLanguageName,
  })
);
const currencyOptions = Object.keys(CURRENCIES_INFO).map(
  (code: CurrencyCode) => ({
    value: code,
    label: `${CURRENCIES_INFO[code].name} (${code})`,
  })
);

export const LocalizationPickerModal = ({
  isOpen,
  setIsOpen,
  storedCurrencyCode,
  storedTimeZone,
  applyChanges,
}: LocalizationPickerModalProps) => {
  const { t } = useTranslation(
    "ssr-client\\components\\FooterLocalizationButton"
  );
  const activeLocale = useLocale();
  const [selectedLanguageLocale, setSelectedLanguageLocale] =
    useState(activeLocale);
  const inferredLocale = useGetInferredLocale();
  useEffect(() => {
    if (inferredLocale && inferredLocale !== activeLocale) {
      setSelectedLanguageLocale(inferredLocale);
      setSelectedCurrencyCode(
        // useGetInferredLocale already infers the locale from IP, so pass ipIsoCountryCode: null
        // and isGlobalDefaultCurrencyTreatment: false to set currency just based on inferredLocale.
        getSuggestedCurrencyCode(inferredLocale, null, false)
      );
    }
  }, [inferredLocale, activeLocale]);

  const [selectedCurrencyCode, setSelectedCurrencyCode] =
    useState<CurrencyCode>(storedCurrencyCode);
  useEffect(() => {
    setSelectedCurrencyCode(storedCurrencyCode);
  }, [storedCurrencyCode]);

  const [selectedTimeZone, setSelectedTimeZone] = useState(
    storedTimeZone || OUTSCHOOL_TIMEZONE
  );
  useEffect(() => {
    setSelectedTimeZone(storedTimeZone);
  }, [storedTimeZone]);

  const selectedContinentTimeZone = toPairs(timeZoneOptionGroups).find(
    ([_continent, timeZones]) => {
      return timeZones.some(({ value }) => value === selectedTimeZone);
    }
  );

  let optGroups = timeZoneOptionGroups;
  if (selectedContinentTimeZone) {
    const [selectedContinent] = selectedContinentTimeZone;
    const pairs = toPairs(timeZoneOptionGroups);
    const sorted = sortBy(pairs, ([continent]) => {
      // Force the selected continent to be sorted first
      return continent === selectedContinent ? "." : continent;
    });
    optGroups = fromPairs(sorted);
  }

  const heading = t`Update Regional Settings`;
  return (
    <PageModal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      ariaLabel={heading}
      sx={{ maxWidth: 500, maxHeight: "100vh" }}
    >
      <Box>
        <Typography variant="h4" gutterBottom>
          {heading}
        </Typography>
        <Typography
          variant="inherit"
          sx={{
            display: "inline-block",
          }}
          gutterBottom
        >
          {t`Select language and currency you use to browse on Outschool.`}
        </Typography>
        <Typography variant="h6" gutterBottom>{t`Language`}</Typography>
        <Box
          sx={{
            position: "relative",
            flex: "auto",
          }}
        >
          <Select
            options={languageOptions}
            onChange={setSelectedLanguageLocale}
            value={selectedLanguageLocale}
            sx={{
              width: "100%",
              borderRadius: "5px",
              marginBottom: "20px",
              paddingLeft: "20px",
            }}
          />
          <Icon
            icon={faGlobeRegular}
            sx={{
              position: "absolute",
              left: 15,
              top: "13px",
              color: "primary.700",
            }}
          />
        </Box>
        <Typography variant="h6" gutterBottom>{t`Currency`}</Typography>
        <Box
          sx={{
            position: "relative",
            flex: "auto",
          }}
        >
          <Select
            options={currencyOptions}
            onChange={setSelectedCurrencyCode}
            value={selectedCurrencyCode}
            sx={{
              width: "100%",
              borderRadius: "5px",
              marginBottom: "20px",
              paddingLeft: "20px",
            }}
          />
          <Icon
            icon={faCoins}
            sx={{
              position: "absolute",
              left: 15,
              top: "13px",
              color: "primary.700",
            }}
          />
        </Box>
        <Typography variant="h6" gutterBottom>{t`Time Zone`}</Typography>
        <Box
          sx={{
            position: "relative",
            flex: "auto",
          }}
        >
          <SelectWithOptGroups
            optGroups={optGroups}
            onChange={setSelectedTimeZone}
            value={selectedTimeZone}
            sx={{
              width: "100%",
              borderRadius: "5px",
              marginBottom: "20px",
              paddingLeft: "20px",
            }}
          />
          <Icon
            icon={faClock}
            sx={{
              position: "absolute",
              left: 15,
              top: "13px",
              color: "primary.700",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button variant="link" onClick={() => setIsOpen(false)}>
            {t`Cancel`}
          </Button>
          <TrackedButton
            variant="contained"
            onClick={async () => {
              await applyChanges(
                selectedLanguageLocale,
                selectedCurrencyCode,
                selectedTimeZone
              );
            }}
            trackingName="apply_modal_localization_changes_button"
            trackingUniqueId="apply_modal_localization_changes_button"
            trackingData={{
              locale: activeLocale,
              storedCurrencyCode,
              storedTimeZone,
              selectedLanguageLocale,
              selectedCurrencyCode,
              selectedTimeZone,
            }}
            trackViews={true}
          >
            {t`Apply Changes`}
          </TrackedButton>
        </Box>
      </Box>
    </PageModal>
  );
};
