import { Box, NavbarSearchBox, Theme } from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";
import { searchPath } from "@outschool/routes";
import { useMobileSearchBar } from "@outschool/ui-components-website";
import { useNavigation } from "@outschool/ui-utils";
import { useRouter } from "next/router";
import React from "react";

import DesktopSearchField from "./DesktopSearchField";
import SearchField from "./SearchField";
import { SearchFiltersContextProvider } from "./useSearchFilters";

export default function GlobalSearchBar() {
  const { t } = useTranslation("ssr-client\\components\\nav\\GlobalSearchBar");
  const navigation = useNavigation();

  return (
    <Box
      sx={(theme: Theme) => ({
        width: "100%",
        paddingX: "1.5em",
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      })}
    >
      <SearchFiltersContextProvider>
        <SearchField
          searchFilters={{}}
          setImplicitSearchFilters={() => {}}
          setSearchFilters={filters => {
            navigation(searchPath(filters), { hardNav: true });
          }}
          clearDropdowns={() => {}}
          searchPlaceholder={t("Search online classes and tutors")}
          SearchBoxComponent={NavbarSearchBox}
          menuStyleSx={{
            top: "45px",
          }}
        />
      </SearchFiltersContextProvider>
    </Box>
  );
}

export function MobileSearchBar() {
  const { pathname } = useRouter();
  // disable on homepage
  const isOnIgnoredPage = ["/[deviceType]"].includes(pathname);
  const { showMobileSearchBar } = useMobileSearchBar();
  if (isOnIgnoredPage || !showMobileSearchBar) {
    return null;
  }

  return (
    <Box
      sx={(theme: Theme) => ({
        width: "100%",
        position: "fixed",
        padding: "5px 10px",
        top: 0,
        left: 0,
        zIndex: 3,
        backgroundColor: theme.palette.common.white,
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        [theme.breakpoints.up("md")]: {
          display: "none",
        },
      })}
    >
      <SearchBar />
    </Box>
  );
}

export function SearchBar() {
  const navigation = useNavigation();
  const { t } = useTranslation("ssr-client\\components\\nav\\GlobalSearchBar");
  return (
    <SearchFiltersContextProvider>
      <DesktopSearchField
        searchFilters={{}}
        setImplicitSearchFilters={() => {}}
        setSearchFilters={filters => {
          navigation(searchPath(filters), { hardNav: true });
        }}
        clearDropdowns={() => {}}
        SearchBoxComponent={NavbarSearchBox}
        searchPlaceholder={t("Search online classes and tutors")}
        menuStyleSx={{
          top: "45px",
        }}
      />
    </SearchFiltersContextProvider>
  );
}
