import { Box, Button, Icon, SxProps } from "@outschool/backpack";
import {
  IconDefinition,
  faGift,
  faHome,
  faQuestionCircle,
  faSearch,
  faSignIn,
  faUserPlus,
} from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import {
  browseRootPath,
  giftCardLandingPath,
  loginPath,
  searchPath,
} from "@outschool/routes";
import { AuthTrigger, useLoginFlowContext } from "@outschool/ui-auth";
import { NavHelpLink } from "@outschool/ui-components-website";
import { Screen, useLinkComponent } from "@outschool/ui-utils";
import { SystemStyleObject } from "@styled-system/css";
import React from "react";

import HeaderLocalizationButton from "../HeaderLocalizationButton";

interface HeaderMenuProps {
  onClose?: any;
  isLoggedIn?: boolean;
}

export default function MobileHeaderMenuComponent(props: HeaderMenuProps) {
  const { isLoggedIn, onClose } = props;
  const { currentUser, isLeader } = {
    currentUser: null,
    isLeader: false,
  };
  const isTablet = Screen.useIsTablet();
  const isHomePath = false;
  const pricingOfferAdminOrgUid = undefined;
  const hasLearner = false;
  const localizationPicker = null;

  const showWalletFunds = isLoggedIn && !!currentUser?.pricingOffer && isTablet;
  const { t } = useTranslation("ssr-client\\components\\nav\\HeaderMenu");

  const menuProps = {
    currentUser,
    pricingOfferAdminOrgUid,
    onClose,
    localizationPicker,
    isHomePath,
    isMobile: isTablet,
    isLeader,
    showWalletFunds,
    hasLearner,
    t,
    isLoggedIn,
  };
  let menuItems = null;
  menuItems = <MobileHeaderMenuItems {...menuProps} />;

  return (
    <Box
      flex
      sx={{
        flexDirection: "column",
        paddingY: "1em",
        overflowY: "auto",
        overscrollBehaviorY: isTablet ? "none" : "inherit",
      }}
    >
      {menuItems}
    </Box>
  );
}

function MobileHeaderMenuItems({
  onClose,
  isMobile,
  localizationPicker,
  t,
  isLoggedIn,
}) {
  const Link = useLinkComponent();
  const helpLinkLabel = t("Help");
  const { enterLoginFlow } = useLoginFlowContext();

  return (
    <>
      {isMobile && <HomeLink onClick={onClose} t={t} />}
      {isMobile && <FindClassesLink onClick={onClose} t={t} />}
      <Link to={giftCardLandingPath()} onClick={onClose}>
        <HeaderMenuItem label={t("Give a Gift Card")} icon={faGift} />
      </Link>
      <HelpLink onClick={onClose} label={helpLinkLabel} />
      {(!isLoggedIn || localizationPicker) && <HeaderMenuSeparator />}
      {localizationPicker}
      {!isLoggedIn && (
        <HeaderLocalizationButton
          onClick={() => {
            onClose();
          }}
          isMobile={isMobile}
        />
      )}
      {!isLoggedIn && (
        <Button
          variant="link"
          sx={{
            fontWeight: "normal",
            justifyContent: "start",
          }}
          onClick={() => {
            enterLoginFlow({
              authTrigger: AuthTrigger.JOIN_OUTSCHOOL,
            });
            onClose();
          }}
        >
          <HeaderMenuItem label={t("Join")} icon={faUserPlus} />
        </Button>
      )}
      {!isLoggedIn && (
        <Link to={loginPath()} onClick={onClose}>
          <HeaderMenuItem label={t("Log In")} icon={faSignIn} />
        </Link>
      )}
    </>
  );
}

function HomeLink({ onClick, t }) {
  const Link = useLinkComponent();
  return (
    <Link to={browseRootPath()} onClick={onClick}>
      <HeaderMenuItem label={t("Home")} icon={faHome} />
    </Link>
  );
}

function FindClassesLink({ onClick, t }) {
  const Link = useLinkComponent();
  return (
    <Link onClick={onClick} to={searchPath()} trackingName="nav_find_classes">
      <HeaderMenuItem label={t("Search")} icon={faSearch} />
    </Link>
  );
}

function HelpLink({ onClick, label }) {
  return (
    <NavHelpLink onClick={onClick} sx={{ justifyContent: "start" }}>
      <HeaderMenuItem label={label} icon={faQuestionCircle} />
    </NavHelpLink>
  );
}

export function HeaderMenuSeparator() {
  return (
    <Box
      sx={{
        height: "1px",
        marginY: "1em",
        backgroundColor: "#ecf1f8",
        flexShrink: 0,
      }}
    />
  );
}

export function HeaderMenuItem({
  label,
  icon,
  children = null,
  iconSx = {},
  badgeNumber = null,
  sx = {},
}: {
  label?: string;
  icon?: IconDefinition;
  children?: React.ReactNode;
  iconSx?: SystemStyleObject;
  sx?: SxProps;
  badgeNumber?: number;
}) {
  return (
    <Box
      flex
      sx={[
        {
          paddingY: "0.5em",
          paddingX: "1em",
          color: "grey.900",
          alignItems: "center",

          "&:hover": {
            backgroundColor: "#ecf1f8",
          },

          position: "relative",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {icon && (
        <Icon
          fixedWidth
          icon={icon}
          sx={[
            {
              marginRight: "0.5em",
              color: "primary.500",
            },
            ...(Array.isArray(iconSx) ? iconSx : [iconSx]),
          ]}
        />
      )}
      {!!badgeNumber && (
        <Box
          sx={{
            position: "absolute",
            left: 30,
            top: "8px",
            background: "red",
            color: "common.white",
            textAlign: "center",
            borderRadius: "50%",
            fontSize: ".85rem",
            width: "1.25rem",
            height: "1.25rem",
            lineHeight: "1.25rem",
            fontWeight: "fontWeightBold",
          }}
        >
          {badgeNumber}
        </Box>
      )}
      {label}
      {children}
    </Box>
  );
}
