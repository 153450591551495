// es-lint-disable-file
import { Box, Icon, SxProps, Theme, visuallyHidden } from "@outschool/backpack";
import { faBars, faSearch } from "@outschool/icons";
import { TFunction, useTranslation } from "@outschool/localization";
import {
  addParamsToUrl,
  browseRootPath,
  forSellerOrgPath,
  leadActivitiesPath,
  loginPath,
  pathFromLocation,
  searchPath,
  sellerOrgPath,
  teachPath,
  teachTipsUrl,
} from "@outschool/routes";
import { AuthTrigger } from "@outschool/ui-auth";
import { ExternalLink } from "@outschool/ui-components-shared";
import {
  CURRENT_PROMOTIONAL_OFFER_BANNER_CAMPAIGN,
  CategoryNavbar,
  NavHelpLink,
  PromotionalOfferBannerExperiment,
  SignUpBannerFromCookie,
  useMobileCDPGroupExperiment,
  useMobileSearchBar,
} from "@outschool/ui-components-website";
import {
  BoxButton,
  ConditionalTrackingPageSection,
  useImpressionTracking,
} from "@outschool/ui-legacy-component-library";
import {
  useIsMobile,
  useIsMounted,
  useLinkComponent,
  useOnScreen,
} from "@outschool/ui-utils";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React, { ReactNode, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

import { MAX_WIDTH } from "../../../../components/LandingPage/constants";
import { isProduction } from "../../../../Env";
import { SitewideProps } from "../../../../lib/sitewideProps";
import { DesktopHeaderNav } from "./DesktopHeaderNav";
import { MobileSearchBar } from "./GlobalSearchBar";
import HeaderLogo from "./HeaderLogo";
import HeaderNavItem from "./HeaderNavItem";
import MobileHeaderMenuComponent from "./MobileHeaderMenuComponent";

const GlobalSearchBar = dynamic(() => import("./GlobalSearchBar"), {
  ssr: false,
});

type Props = {
  trackingUniqueId?: string;
  isTransparent?: boolean;
  isWhiteText?: boolean;
  allowGlobalNavbar?: boolean;
  sx?: SxProps;
  showSearchBar?: boolean;
  joinButton?: React.ReactNode;
  isLoggedIn?: boolean;
  hideFlexContainerPadding?: boolean;
} & Partial<Pick<SitewideProps, "promotionalOfferBannerData">>;

export const Header: React.FC<Props> = ({
  trackingUniqueId,
  isTransparent,
  isWhiteText = true,
  allowGlobalNavbar = false,
  sx,
  showSearchBar,
  joinButton,
  isLoggedIn,
  hideFlexContainerPadding,
  ...props
}) => {
  const isEducatorMode = false;
  const enableMobileCDPGroup1Exp = useMobileCDPGroupExperiment();

  const showCategoryNavbar =
    !enableMobileCDPGroup1Exp && !isEducatorMode && allowGlobalNavbar;
  showSearchBar = !enableMobileCDPGroup1Exp && showSearchBar;
  const { t } = useTranslation("ssr-client\\components\\nav\\Header");

  return (
    <>
      <PromotionalOfferBannerExperiment
        campaignVariant={CURRENT_PROMOTIONAL_OFFER_BANNER_CAMPAIGN}
        promotionalOfferBannerData={props.promotionalOfferBannerData}
        isProduction={isProduction}
      />
      <ConditionalTrackingPageSection
        name="header"
        uniqueId={trackingUniqueId ? trackingUniqueId : "header"}
      >
        {setImpressionNode => (
          <Box ref={setImpressionNode}>
            {isTransparent ? (
              <TransparentHeader
                isWhiteText={isWhiteText}
                showCategoryNavbar={showCategoryNavbar}
                t={t}
              />
            ) : (
              <StandardHeader
                hideFindClasses={isEducatorMode}
                showCategoryNavbar={showCategoryNavbar}
                sx={sx}
                showSearchBar={showSearchBar}
                joinButton={joinButton}
                isLoggedIn={isLoggedIn}
                hideFlexContainerPadding={hideFlexContainerPadding}
              />
            )}
          </Box>
        )}
      </ConditionalTrackingPageSection>
    </>
  );
};

function HeaderFlexContainer({
  children,
  sx = {},
  hideFlexContainerPadding = false,
}: React.PropsWithChildren<{
  sx?: SxProps;
  hideFlexContainerPadding?: boolean;
}>) {
  const { pathname } = useRouter();
  // no mobile padding on landing pages
  const noMobilePadding = ["/[deviceType]/landing-page/[slug]"].includes(
    pathname
  );
  return (
    <Box
      sx={theme => ({
        maxWidth: MAX_WIDTH,
        paddingX: hideFlexContainerPadding ? 0 : "1em",
        margin: "auto",
        [theme.breakpoints.down("sm")]: {
          paddingX: noMobilePadding ? 0 : "1em",
        },
      })}
    >
      <Box
        flex
        sx={(theme: Theme) => ({
          alignItems: "center",
          justifyContent: "space-between",

          [theme.breakpoints.up("md")]: {
            padding: 0,
          },
          ...sx,
        })}
      >
        {children}
      </Box>
    </Box>
  );
}

function StandardHeaderLeft() {
  const Link = useLinkComponent();
  const rootPath = browseRootPath();
  return (
    <Link to={rootPath} trackingName="nav_logo" aria-label="homepage button">
      <HeaderNavItem sx={{ paddingX: 0 }}>
        <HeaderLogo isWhiteText={false} />
      </HeaderNavItem>
    </Link>
  );
}

function ListingsPageLink({ t }: { t: TFunction }) {
  const Link = useLinkComponent();
  return (
    <Link to={searchPath()} trackingName="nav_find_classes">
      <HeaderNavItem>
        <Icon
          icon={faSearch}
          sx={{
            fontSize: "1.333em",
          }}
        />
      </HeaderNavItem>
      <span style={visuallyHidden}>{t`Search`}</span>
    </Link>
  );
}

function MobileHeaderMenuButton({
  isTransparent,
  isWhiteText,
  t,
  isLoggedIn,
}: {
  isTransparent?: boolean;
  isWhiteText?: boolean;
  t: TFunction;
  isLoggedIn?: boolean;
}) {
  const [numCloseCount, setNumCloseCount] = React.useState(0);
  const [mobileHeaderMenuOpen, setMobileHeaderMenuOpen] = React.useState(false);
  const [mobileHeaderMenuOpenNode, setMobileHeaderMenuOpenNode] =
    React.useState<HTMLDivElement>(null);
  const { trackTouch: trackMobileHeaderMenuOpenButtonTouch } =
    useImpressionTracking({
      node: mobileHeaderMenuOpenNode,
      trackingLabel: "mobile_header_menu_open_button",
      uniqueId: `mobile_header_menu_open_button-${numCloseCount}`,
      sharedProperties: {
        numCloseCount,
      },
    });
  const closeHeaderMenu = () => {
    setMobileHeaderMenuOpen(false);
    setNumCloseCount(numCloseCount + 1);
  };
  return (
    <>
      <HeaderNavItem
        variant={isTransparent ? "transparent" : undefined}
        onClick={() => {
          trackMobileHeaderMenuOpenButtonTouch();
          setMobileHeaderMenuOpen(true);
        }}
        ref={setMobileHeaderMenuOpenNode}
        role="menuitem"
      >
        <Icon
          icon={faBars}
          sx={{
            fontSize: "1.333em",
            color: isWhiteText ? "common.white" : "grey.500",
          }}
        />
        <span style={visuallyHidden}>{t`Menu`}</span>
      </HeaderNavItem>
      <MobileHeaderMenu
        open={mobileHeaderMenuOpen}
        onClose={closeHeaderMenu}
        isLoggedIn={isLoggedIn}
      />
    </>
  );
}

interface StandardHeaderRightProps {
  hideFindClasses: boolean;
  t: TFunction;
  joinButton?: React.ReactNode;
  isLoggedIn?: boolean;
}

const StandardHeaderRight: React.FC<StandardHeaderRightProps> = ({
  hideFindClasses,
  t,
  joinButton,
  isLoggedIn,
}) => {
  const isMobile = useIsMobile();

  return isMobile ? (
    <Box
      flex
      sx={{
        gap: "1.5em",
        paddingY: "0.5em",
      }}
    >
      {!hideFindClasses && <ListingsPageLink t={t} />}
      <MobileHeaderMenuButton t={t} isLoggedIn={isLoggedIn} />
    </Box>
  ) : (
    <DesktopHeaderNav joinButton={joinButton} isLoggedIn={isLoggedIn} />
  );
};

interface StandardHeaderProps {
  showSearchBar?: boolean;
  sx?: SxProps;
  hideFindClasses?: boolean;
  leftChildren?: any;
  rightChildren?: any;
  showCategoryNavbar?: boolean;
  joinButton?: ReactNode;
  isLoggedIn?: boolean;
  hideFlexContainerPadding?: boolean;
}

export const StandardHeader: React.FC<StandardHeaderProps> = ({
  showSearchBar = true,
  sx,
  hideFindClasses,
  leftChildren,
  rightChildren,
  showCategoryNavbar = false,
  joinButton,
  isLoggedIn = false,
  hideFlexContainerPadding,
}) => {
  const { setIsHeaderMounted, setIsHeaderOnScreen } = useMobileSearchBar();
  const ref = useRef<HTMLElement>(null);
  const isOnScreen = useOnScreen(ref.current);
  const { t } = useTranslation("ssr-client\\components\\nav\\Header"); //StickyMobileHeader imports this as well

  useEffect(() => {
    setIsHeaderOnScreen(isOnScreen);
  }, [setIsHeaderOnScreen, isOnScreen]);

  useEffect(() => {
    const t = setTimeout(() => setIsHeaderMounted(true), 1000);
    return () => {
      clearTimeout(t);
      setIsHeaderMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (leftChildren === undefined) {
    // if null, omit left header
    leftChildren = <StandardHeaderLeft />;
  }
  if (rightChildren === undefined) {
    // if null, omit right header
    rightChildren = (
      <StandardHeaderRight
        hideFindClasses={hideFindClasses}
        t={t}
        joinButton={joinButton}
        isLoggedIn={isLoggedIn}
      />
    );
  }

  return (
    <>
      <Box
        ref={ref}
        sx={[
          {
            position: "relative",
            backgroundColor: "white",
            boxShadow: "0px 1px 12px rgba(0, 0, 0, 0.08)",
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <HeaderFlexContainer
          sx={theme => ({
            gap: "1.5em",
            [theme.breakpoints.down("sm")]: {
              paddingX: "1rem",
            },
          })}
          hideFlexContainerPadding={hideFlexContainerPadding}
        >
          {leftChildren}
          {showSearchBar && <GlobalSearchBar />}
          {showSearchBar && <MobileSearchBar />}
          {rightChildren}
        </HeaderFlexContainer>
        {showCategoryNavbar && (
          <CategoryNavbar
            sx={(theme: Theme) => ({
              [theme.breakpoints.down("md")]: {
                borderTop: "1px solid",
              },
              borderColor: "#808080",
            })}
          />
        )}
      </Box>
      <SignUpBannerFromCookie />
    </>
  );
};

function TransparentHeader({ isWhiteText, showCategoryNavbar, t }) {
  const Link = useLinkComponent();
  const isMobile = useIsMobile();
  const rootPath = browseRootPath();
  const variantMemo = React.useMemo(() => {
    return isWhiteText ? "transparent" : "standard";
  }, [isWhiteText]);
  return (
    <Box
      sx={{
        backgroundColor: "common.white",
      }}
    >
      <HeaderFlexContainer>
        <Box flex>
          <Link to={rootPath} trackingName="nav_logo">
            <HeaderNavItem
              variant={variantMemo}
              sx={
                !isWhiteText
                  ? {
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.5)",
                      },
                    }
                  : {}
              }
            >
              <HeaderLogo isWhiteText={isWhiteText} />
            </HeaderNavItem>
          </Link>
        </Box>

        {isMobile ? (
          <MobileHeaderMenuButton
            isTransparent={true}
            isWhiteText={isWhiteText}
            t={t}
          />
        ) : (
          <DesktopTransparentHeaderNav isWhiteText={isWhiteText} t={t} />
        )}
      </HeaderFlexContainer>
      {showCategoryNavbar && (
        <CategoryNavbar
          sx={(theme: Theme) => ({
            [theme.breakpoints.down("md")]: {
              borderTop: "1px solid",
            },
            borderBottom: "1px solid",
            borderColor: "#808080",
          })}
        />
      )}
    </Box>
  );
}

function DesktopTransparentHeaderNav({ isWhiteText, t }) {
  const Link = useLinkComponent();
  const isLoggedIn = false;
  const router = useRouter();
  const pathname = router.asPath;
  const isTeacherPath = pathname === teachPath();
  const isSellerOrgPath =
    pathname === forSellerOrgPath() || pathname === sellerOrgPath();

  return (
    <Box
      flex
      sx={{
        gap: "0.5em",
      }}
    >
      {!isLoggedIn && isTeacherPath && (
        <ExternalLink url={teachTipsUrl()} trackingName="nav_library_link">
          <HeaderNavItem variant={isWhiteText ? "transparent" : "standard"}>
            {t`Educator Library`}
          </HeaderNavItem>
        </ExternalLink>
      )}
      <Link to={leadActivitiesPath()} trackingName="nav_teach_link">
        <HeaderNavItem variant={isWhiteText ? "transparent" : "standard"}>
          {t`Teach`}
        </HeaderNavItem>
      </Link>
      <NavHelpLink>
        <HeaderNavItem variant={isWhiteText ? "transparent" : "standard"}>
          {t`Help`}
        </HeaderNavItem>
      </NavHelpLink>
      {!isLoggedIn && (
        <Link to={loginPath()} trackingName="nav_login_link">
          <HeaderNavItem variant={isWhiteText ? "transparent" : "standard"}>
            {t`Log In`}
          </HeaderNavItem>
        </Link>
      )}
      {!isLoggedIn && !isSellerOrgPath && (
        <Link
          to={addParamsToUrl(pathFromLocation(window.location), {
            authTrigger: AuthTrigger.JOIN_OUTSCHOOL,
            signup: true,
          })}
          trackingName="nav_signup_link"
        >
          <HeaderNavItem
            variant={isWhiteText ? "transparent" : "standard"}
            sx={{ color: isWhiteText ? "common.white" : "#4B01D4" }}
          >
            {t`Join`}
          </HeaderNavItem>
        </Link>
      )}
    </Box>
  );
}

interface MobileHeaderMenuProps {
  open: boolean;
  onClose: () => void;
  isLoggedIn: boolean;
}

export function MobileHeaderMenu({
  open,
  onClose,
  isLoggedIn,
}: MobileHeaderMenuProps): React.ReactPortal {
  const isMounted = useIsMounted();

  return !isMounted
    ? null
    : ReactDOM.createPortal(
        <Box>
          <BoxButton
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              backgroundColor: "rgba(0,0,0,0.6)",
              zIndex: 2000,
              pointerEvents: open ? "initial" : "none",
              opacity: open ? 1 : 0,
              transition: "opacity 0.3s ease",
            }}
            onClick={onClose}
          />
          <Box
            flex
            sx={{
              flexDirection: "column",
              position: "fixed",
              right: open ? 0 : "-100vw",
              top: 0,
              bottom: 0,
              backgroundColor: "common.white",
              zIndex: 2001,
              transition: "right 0.3s ease",
              minWidth: "75vw",
            }}
          >
            <MobileHeaderMenuComponent
              onClose={onClose}
              isLoggedIn={isLoggedIn}
            />
          </Box>
        </Box>,
        document.body
      );
}
